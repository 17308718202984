<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0 text-capitalize">
          {{
            (isAdd ? $t("actions.add") : $t("actions.edit")) +
            " " +
            $tc("unit.title", 1)
          }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Description -->
          <validation-provider
            #default="validationContext"
            name="description"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('unit.description')"
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="itemData.description"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- ID Tenant -->
          <validation-provider
            #default="validationContext"
            name="tenant"
            rules="required"
          >
            <b-form-group
              label="Tenant"
              label-for="id_tenant"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_tenant"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tenantOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_tenant"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- country -->
          <validation-provider
            #default="validationContext"
            name="id_country"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$tc('country.title', 1)"
              label-for="id_country"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countryOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_country"
                @input="changeCountry"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- region -->
          <validation-provider
            #default="validationContext"
            name="id_region"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$tc('region.title', 1)"
              label-for="id_estado"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_region"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="regionOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_estado"
                @input="changeRegion"
                :disabled="!itemData.id_country"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

           <!-- municipality -->
           <validation-provider
            #default="validationContext"
            name="id_municipality"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$tc('municipality.title', 1)"
              label-for="id_municipality"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_municipality"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="municipalityOptions"
                :clearable="true"
                :reduce="(val) => val.value"
                input-id="id_municipality"
                :disabled="!itemData.id_region"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Direccion 1 -->
          <validation-provider
            #default="validationContext"
            name="address_1"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('unit.address_1')"
              label-for="address_1"
            >
              <b-form-input
                id="address_1"
                v-model="itemData.address_1"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

           <!-- reference -->
          <validation-provider
            #default="validationContext"
            name="address_2"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('unit.address_2')"
              label-for="address_2"
            >
              <b-form-input
                id="address_2"
                v-model="itemData.address_2"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- exterior number -->
          <validation-provider
            #default="validationContext"
            name="exterior_number"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('unit.exterior_number')"
              label-for="exterior_number"
            >
              <b-form-input
                id="exterior_number"
                v-model="itemData.exterior_number"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Suburb Colonia -->
          <validation-provider
            #default="validationContext"
            name="suburb"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('unit.suburb')"
              label-for="suburb"
            >
              <b-form-input
                id="suburb"
                v-model="itemData.suburb"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Número -->
          <validation-provider
            #default="validationContext"
            name="number"
            required="true"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('unit.number')"
              label-for="number"
            >
              <b-form-input
                id="number"
                v-model="itemData.number"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- CP -->
          <validation-provider
            #default="validationContext"
            name="postal_code"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('unit.postal_code')"
              label-for="postal_code"
            >
              <b-form-input
                id="postal_code"
                v-model="itemData.postal_code"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Reaction Patrol-->
          <validation-provider
            #default="validationContext"
            name="reaction_patro"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('unit.reaction_patrol')"
              label-for="reaction_patrol"
            >
              <b-form-input
                id="reaction_patrol"
                v-model="itemData.reaction_patrol"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- TotemID -->
          <validation-provider
            #default="validationContext"
            name="idTotem"
          >
            <b-form-group label="Id Totem" label-for="idTotem">
              <b-form-input
                id="idTotem"
                v-model="itemData.idTotem"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- TotemKey -->
          <validation-provider
            #default="validationContext"
            name="totemKey"
          >
            <b-form-group label="TotemKey" label-for="totemKey">
              <b-form-input
                id="totemKey"
                v-model="itemData.totemKey"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Latitud -->
          <validation-provider
            #default="validationContext"
            name="lat"
            rules="required|between:-90,90"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('router.latitude')"
              label-for="lat"
            >
              <b-form-input
                id="lat"
                v-model="itemData.lat"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Longitud -->
          <validation-provider
            #default="validationContext"
            name="lng"
            rules="required|between:-180,180"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('router.longitude')"
              label-for="lng"
            >
              <b-form-input
                id="lng"
                v-model="itemData.lng"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Altitud -->
          <validation-provider
            #default="validationContext"
            name="alt"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('router.altitude')"
              label-for="alt">
              <b-form-input
                id="alt"
                v-model="itemData.alt"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!--Permitir Botón Virtual -->
          <validation-provider name="vehicle">
            <b-form-checkbox
              id="allow_virtual_button_alarm"
              v-model="itemData.allow_virtual_button_alarm"
              name="allow_virtual_button_alarm"
              value="1"
              unchecked-value="0"
              class="my-1"
              switch
            >
             {{ $t("unit.allow_virtual_button_alarm") }}
            </b-form-checkbox>
          </validation-provider>

          <!--Permitir Salida Digital -->
          <validation-provider name="active_digital_output">
            <b-form-checkbox
              id="active_digital_output"
              v-model="itemData.active_digital_output"
              name="active_digital_output"
              value="1"
              unchecked-value="0"
              class="my-1"
              switch
            >
              {{ $t("unit.active_digital_output") }}
            </b-form-checkbox>
          </validation-provider>

          <!-- notify_c5_cdmx -->
          <validation-provider name="notify_c5_cdmx" rules="required">
            <b-form-checkbox
              id="notify_c5_cdmx"
              v-model="itemData.notify_c5_cdmx"
              name="notify_c5_cdmx"
              value="1"
              unchecked-value="0"
              class="my-1 text-capitalize"
              switch
            >
              {{$t('router.notify_cad_c5_cdmx')}}
            </b-form-checkbox>
          </validation-provider>

          <!-- notify_c5_jal -->
          <validation-provider name="notify_c5_jal" rules="required">
            <b-form-checkbox
              id="notify_c5_jal"
              v-model="itemData.notify_c5_jal"
              name="notify_c5_jal"
              value="1"
              unchecked-value="0"
              class="my-1 text-capitalize"
              switch
            >
             {{$t('router.notify_cad_c5_jalisco')}}
            </b-form-checkbox>
          </validation-provider>

          <!-- Router -->
          <validation-provider>
            <b-form-checkbox
              v-model="itemData.hasRouter"
              value="1"
              unchecked-value="0"
              class="my-1 text-capitalize"
              switch
            >
              {{ $t('router.has_router') }}
            </b-form-checkbox>
          </validation-provider>

          <!-- IMEI -->
          <validation-provider
            #default="validationContext"
            name="imei"
            rules="required"
            v-if="itemData.hasRouter == 1 ? true : false"
          >
            <b-form-group :label="$t('router.imei')" label-for="imei">
              <b-form-input
                id="imei"
                v-model="itemData.imei"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- ip_vpn -->
          <validation-provider
            #default="validationContext"
            name="ip_vpn"
            rules="ip"
            v-if="itemData.hasRouter == 1 ? true : false"
          >
            <b-form-group label="IP VPN" label-for="ip_vpn">
              <b-form-input
                id="ip_vpn"
                v-model="itemData.ip_vpn"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Es Vehiculo -->
          <validation-provider name="vehicle">
            <b-form-checkbox
              id="vehicle"
              v-model="itemData.vehicle"
              name="vehicle"
              value="1"
              unchecked-value="0"
              class="my-1text-capitalize "
              switch
            >
              {{ $t("unit.vehicle") }}
            </b-form-checkbox>
          </validation-provider>

          <!-- Ruta -->
          <validation-provider
            #default="validationContext"
            name="route"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('unit.route')"
              label-for="route"
              v-if="itemData.vehicle == 1 ? true : false"
            >
              <b-form-input
                id="route"
                v-model="itemData.route"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Placa-->
          <validation-provider
            #default="validationContext"
            name="plate_number"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('unit.plate_number')"
              label-for="plate_number"
              v-if="itemData.vehicle == 1 ? true : false"
            >
              <b-form-input
                id="plate_number"
                v-model="itemData.plate_number"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- VIN-->
          <validation-provider
            #default="validationContext"
            name="vin"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('unit.vin')"
              label-for="vin"
              v-if="itemData.vehicle == 1 ? true : false"
            >
              <b-form-input
                id="vin"
                v-model="itemData.vin"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Conductor -->
          <validation-provider
            #default="validationContext"
            name="driver"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('unit.driver')"
              label-for="driver"
              v-if="itemData.vehicle == 1 ? true : false"
            >
              <b-form-input
                id="driver"
                v-model="itemData.driver"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Proveedor-->
          <validation-provider
            #default="validationContext"
            name="provider"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('unit.provider')"
              label-for="provider"
              v-if="itemData.vehicle == 1 ? true : false"
            >
              <b-form-input
                id="provider"
                v-model="itemData.provider"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Concesionario-->
          <validation-provider
            #default="validationContext"
            name="owner"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('unit.owner')"
              label-for="owner"
              v-if="itemData.vehicle == 1 ? true : false"
            >
              <b-form-input
                id="owner"
                v-model="itemData.owner"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Compañia-->
          <validation-provider
            #default="validationContext"
            name="company"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('unit.company')"
              label-for="company"
              v-if="itemData.vehicle == 1 ? true : false"
            >
              <b-form-input
                id="company"
                v-model="itemData.company"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              data-cy="main-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ isAdd ? $t("actions.add") : $t("actions.save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              class="text-capitalize"
              @click="hide"
            >
               {{$t("actions.cancel")}}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import {
  required, alphaNum, email, positive, required_if,
} from '@validations'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
    tenantOptions: {
      type: Array,
      required: true,
    },
    countryOptions: {
      type: Array,
      required: true,
    },
    regionOptions: {
      type: Array,
      required: true,
    },
    municipalityOptions: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    }
  },
  setup(props, { emit }) {
    const blankData = {
      description: '',
      address_1: '',
      address_2: '',
      suburb: '',
      number: '',
      postal_code: '',
      id_country: '',
      id_region: '',
      route: '',
      plate_number: '',
      vin: '',
      driver: '',
      provider: '',
      owner: '',
      company: '',
      reaction_patrol: '',
      vehicle:0,
      allow_virtual_button_alarm: 0,
      active_digital_output: 0,
      active: 1,
      id_tenant: '',
      imei: '',
      idTotem: '',
      totemKey: '',
      ip_vpn: '',
      lat: '0',
      lng: '0',
      alt: '0',
      notify_c5_cdmx: 0,
      notify_c5_jal: 0,
    }

    const toast = useToast()

    const itemData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData))
    }

    const hasRouter = ref(null);

    const onSubmit = () => {
      let dispatch;

      if(props.isAdd) {
        itemData.value.imei =  ( itemData.value.imei == '' ) ? 'IMEI_' + itemData.value.description : itemData.value.imei
        dispatch = store.dispatch('unit/add', itemData.value)
      }
      else
      {
        const reqRouter = {
          id: itemData.value.id,
          params: {
            id_tenant: itemData.value.id_tenant,
            imei: itemData.value.imei,
            idTotem: itemData.value.idTotem,
            totemKey: itemData.value.totemKey,
            ip_vpn: itemData.value.ip_vpn,
            lat: itemData.value.lat,
            lng: itemData.value.lng,
            alt: itemData.value.alt,
            notify_c5_cdmx: itemData.value.notify_c5_cdmx,
            notify_c5_jal: itemData.value.notify_c5_jal,
            description: itemData.value.description,
            address_1: itemData.value.address_1,
            address_2: itemData.value.address_2,
            exterior_number: itemData.value.exterior_number,
            suburb: itemData.value.suburb,
            number: itemData.value.number,
            postal_code: itemData.value.postal_code,
            id_country: itemData.value.id_country,
            id_region: itemData.value.id_region,
            id_municipality: itemData.value.id_municipality,
            route: itemData.value.route,
            plate_number: itemData.value.plate_number,
            vin: itemData.value.vin,
            driver: itemData.value.driver,
            provider: itemData.value.provider,
            owner: itemData.value.owner,
            company: itemData.value.company,
            vehicle: itemData.value.vehicle,
            reaction_patrol: itemData.value.reaction_patrol,
            allow_virtual_button_alarm: itemData.value.allow_virtual_button_alarm,
            active_digital_output: itemData.value.active_digital_output,
          }
        }

        dispatch = store.dispatch('unit/edit', reqRouter)
      }

      dispatch
        .then(response => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            emit('refetch-data')
            emit('update:is-sidebar-active', false)
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        }).catch(response => {
          if (response.response.status == 422) {
            let string = ``
            Object.entries(response.response.data.data).forEach(entry => {
              const [key, value] = entry
              string += `${key} - ${value}<br>`
            })
            toast({
              component: ToastificationContent,
              props: {
                title: `${response.response.data.message}`,
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: string
              },
            },{
              timeout: 10000
            }
            )
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    const loadData = () => {
      if(!props.isAdd) {
        itemData.value =  {
          ...props.itemEdit.router,
          ...props.itemEdit,
        }
      }
    }

    const changeCountry = () => {
      store
        .dispatch("region/getAll", {
          id_country: [ itemData.value.id_country ],
        })
        .then(() => {
          const region = props.countryOptions.filter(
            (item) => item.value == itemData.value.id_region
          )

          if (region.length == 0) itemData.value.id_region = null
        })
    }

    const changeRegion = () => {
      store
        .dispatch("municipality/getAll", {
          region: [ itemData.value.id_region ],
        })
        .then(() => {
          const municipality = props.regionOptions.filter(
            (item) => item.value == itemData.value.id_municipality
          )

          if (municipality.length == 0) itemData.value.id_municipality = null
        })
    }

    return {
      itemData,
      onSubmit,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
      hasRouter,

      changeCountry,
      changeRegion,
    }
  },
}
</script>
